<template>
  <footer class="row pieDePagina">
    <div class="col-12 px-0 boton-escritorio">
      <button class="btn btn-primary w-100 rounded-0 p-2" @click="versionEscritorio()">
        Versión de escritorio
        <font-awesome-icon icon="sign-out-alt" class="ml-1" />
      </button>
    </div>

    <div class="col-12 py-3 disclaimer">
      <p class="text-center small m-0">
        Los resultados presentados tienen un carácter informativo y no son definitivos,
        por tanto, no tienen efectos jurídicos. Con base en la Ley Federal del Derecho de
        Autor queda prohíbida cualquier modificación al diseño de este sitio. <br />
        La modificación y/o destrucción de la información y/o contenido total o parcial
        de este sitio, es delito federal de acuerdo al Código Penal Federal.
      </p>
    </div>

    <div class="col-12 seccion-logos">
      <div class="grilla">
        <div class="logo">
          <img src="@/assets/ieebcs.png" alt="" class="img-fluid ml-auto d-block">
        </div>
        <div class="logo">
          <img src="@/assets/PrepLogoFooter.png" alt="" class="img-fluid mr-auto d-block">
        </div>
      </div>
    </div>
    <div class="col-12 aviso-privacidad text-center pt-5 pb-2">
      <router-link to="#">
        <u>
          Aviso de privacidad
        </u>
      </router-link>

      <p class="my-5">
        Instituto Estatal Electoral de Baja California Sur
      </p>

      <span>
        Versión 1.0
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PieDePagina',
  mutations: {
    setearEstado(state, { propiedad, payload, subpropiedad = null }) {
      if (subpropiedad) {
        state[propiedad][subpropiedad] = payload;
        return null;
      }
      state[propiedad] = payload;
      return null;
    },
  },
  methods: {
    versionEscritorio() {
      this.$store.state.anchoPantalla = 2000;
      this.$store.state.esVersionEscritorio = true;
      this.$store.commit('cambiarZoom', 30);
    },
  },
};
</script>

<style lang="scss" scoped>
.boton-escritorio {
  .btn {
    background-color: #34344e;
    color: #fff;
    border-color: transparent;
  }
}

.seccion-logos {
  padding: 10px;
  background-color: #D3097F;
  .grilla {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    img {
      max-width: 75px;
    }
  }
}

.aviso-privacidad {
  background-color: #34344e;
  color: #fff;
}
</style>
