<template>
  <section class="avance-entidad row mb-4">
    <div class="col-12 col-md-6 mx-auto" v-if="cargaTerminada">
      <div class="tarjeta">
        <div class="titulo">
          <button class="recarga" @click="recargarPagina">
            <font-awesome-icon icon="redo" />
          </button>
          <h4>
            Avance Entidad
          </h4>
        </div>

        <div class="actas-capturadas">
          <div class="conteo">
            <p class="small mb-0">
              <b>Actas capturadas</b>
            </p>
            <p>
              <b>{{ avance.actas_capturadas_numero }}</b> de {{ avance.actas_esperadas_numero }}
            </p>
          </div>
          <div class="progreso">
            <p class="small mb-1">
              ({{ avance.actas_capturadas_porcentaje }}%)
            </p>
            <b-progress :value="avance.actas_capturadas_porcentaje" max="100" height="1.2 rem" />
          </div>
        </div>

        <div class="participacion-ciudadana">
          <div class="participacion pr-2 border-right">
            <p class="small mb-1 font-weight-bold text-right">
              <u>
                Participación ciudadana
              </u>
            </p>
            <p class="mb-0 text-right">
              {{ avance.participacion_ciudadana_porcentaje}}%
            </p>
          </div>
          <div class="ultimo-corte pr-2 border-right">
            <p class="small mb-2 font-weight-bold text-center">
              Último corte
            </p>
            <p class="mb-0 text-center small">
              {{ avance.fecha_ultimo_corte }}
            </p>
          </div>
          <div class="hora-local">
            <p class="small mb-2 font-weight-bold text-center">
              Hora Local
            </p>
            <p class="mb-0 text-center small">
              {{ avance.hora_local }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AvanceEntidad',
  methods: {
    recargarPagina () {
      window.location.reload()
    }
  },
  computed: {
    avance () {
      if (this.$route.params.eleccion == "D") {
        return this.$store.state.dAvance;
      } else if (this.$route.params.eleccion == "A") {
        return this.$store.state.aAvance;
      } else{
        return this.$store.state.gAvance;
      }
    },
    cargaTerminada () {
      return this.$store.state.cargaTerminada
    }
  }
}
</script>

<style lang="scss" scoped>
.avance-entidad {
  .titulo {
    margin-bottom: 20px;

    h4 {
      color: #d2127e;
    }
    .recarga {
      float: right;
      border: none;
      border-radius: 50%;
      padding: 7px 10px;
      background-color: #d2127e;
      color: #fff;
    }
  }

  .actas-capturadas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    .conteo, .progreso {
      text-align: right;
    }
  }

  .participacion-ciudadana {
    display: grid;
    grid-template-columns: 1fr 30% 23%;
    gap: 5px;
    align-items: flex-end;
  }

  .tarjeta {
    box-shadow: 0px 0px 6px 2px #ccc;
    border-radius: 5px;
    padding: 15px;
  }
}
</style>
