<template>
  <div class="row consulta-votacion">
    <div class="col-12 col-md-8 mx-auto py-3">
      <h4>Consulta de votación</h4>
      <p>
        Selecciona el reporte que deseas consultar:
      </p>

      <div class="grilla-botones" v-if="!seccion">
        <button
          class="btn btn-primary"
          @click="irAVotosPorCandidatura"
          :class="{ 'seleccionado': !esPartidoPolitico }"
        >
          {{ textoBoton }}
        </button>
        <button
          class="btn btn-primary"
          @click="irAVotosPorPartidoPoliticoYCandIndependiente"
          :class="{ 'seleccionado': esPartidoPolitico }"
        >
          Votos por Partido Político y<br />
          Candidatura Independiente
        </button>
      </div>

      <select
        v-if="this.eleccion === 'G' || this.eleccion === 'D'"
        class="form-control"
        v-model="distrito"
        @change="cambiarVista"
      >
        <option :value="null">Selecciona</option>
        <option :value="distrito.id" v-for="(distrito, indice) in listaDistritos" :key="indice">
          {{ distrito.id }}. {{ distrito.descripcion }}
        </option>
      </select>
      <select v-else  class="form-control" v-model="distrito" @change="cambiarVista">
        <option :value="null">Selecciona</option>
         <option :value="municipio.municipio_id"
              v-for="(municipio, indice) in listaMunicipios"
              :key="indice">
          {{municipio.municipio_id}}. {{ municipio.municipio_descripcion }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConsultaVotacionMovil',
  props: {
    seccion: {
      type: Boolean,
    },
  },
  data() {
    return {
      distrito: null,
    };
  },
  created() {
    this.distrito = this.$route.params.distrito ?? null;
  },
  methods: {
    cambiarVista() {
      const tipo_vista = this.$router.currentRoute.name.includes('VPP') ? 'VPP' : 'VPC';
      if (this.distrito == null) {
        this.$router.push(`/${this.eleccion}/ENT/${tipo_vista}`);
      } else {
        this.$router.push(`/${this.eleccion}/DET/${tipo_vista}/${this.distrito}`);
      }
    },
    irAVotosPorCandidatura() {
      if (this.$router.currentRoute.name === 'VotosPorPartidoPolitico') {
        const { eleccion } = this.$route.params;
        this.$router.push(`/${eleccion}/ENT/VPC`);
      } else if (this.$router.currentRoute.name === 'DetalleDelDistritoVPP') {
        const { eleccion } = this.$route.params;
        this.$router.push(`/${eleccion}/DET/VPC/${this.distrito}`);
      }
    },
    irAVotosPorPartidoPoliticoYCandIndependiente() {
      if (this.$router.currentRoute.name === 'VotosPorCandidaturas') {
        const { eleccion } = this.$route.params;
        this.$router.push(`/${eleccion}/ENT/VPP`);
      } else if (this.$router.currentRoute.name === 'DetalleDelDistrito') {
        const { eleccion } = this.$route.params;
        this.$router.push(`/${eleccion}/DET/VPP/${this.distrito}`);
      }
    },
  },
  computed: {
    textoBoton() {
      if (this.$router.currentRoute.name === 'VotosPorCandidaturas' && this.$route.params.eleccion === 'D') {
        return 'Votos por Distritos';
      } if (this.$router.currentRoute.name === 'VotosPorCandidaturas' && this.$route.params.eleccion === 'A') {
        return 'Votos por Municipios';
      }
      return 'Votos por Candidatura';
    },
    eleccion() {
      return this.$route.params.eleccion;
    },
    regiones() {
      return this.$route.params.eleccion === 'A'
        ? this.$store.state.regiones.municipios
        : this.$store.state.regiones.distritos;
    },
    esPartidoPolitico() {
      return this.$router.currentRoute.name.includes('VPP') || this.$router.currentRoute.name.includes('PartidoPolitico');
    },
    elementosGenerales() {
      return this.$store.state.elementosGenerales;
    },
    listaDistritos() {
      return this.$store.state.elementosGenerales.datos_distritos;
    },
    listaMunicipios() {
      return this.$store.state.elementosGenerales.datos_municipios;
    },
    listaSecciones() {
      return this.$store.state.elementosGenerales
        .datos_secciones
        .distritos[this.distrito - 1]
        .datos_secciones;
    },
    listaCasillas() {
      return this.$store.state.elementosGenerales
        .datos_casillas
        .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0];
    },
  },
  watch: {
    $route(val) {
      if ((val.params.distrito !== this.distrito) && val.params.distrito) {
        this.distrito = val.params.distrito;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.consulta-votacion {
  background-color: #34344e;
  color: #FFF;
  margin-bottom: 15px;

  .grilla-botones {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    margin-bottom: 15px;

    .btn {
      font-size: 12px;
      color: #FFF;
      background-color: #604768;
      border-color: transparent;
      transition: all ease .2s;
    }
    .btn.seleccionado {
      background-color: #e97ebd;
      border-color: transparent;
      color: #000;
      font-weight: bold;
    }
  }

  select.form-control {
    max-width: 250px;
    margin: 0 auto;
    border-radius: 20px;
  }
}
</style>
