<template>
  <section>
    <div v-if="cargaTerminada">
      <MenuMovil />
      <div class="container-fluid contenido">
        <AvanceEntidad />
        <ConsultaVotacion v-if="!extranjero" :seccion="seccion" />
        <slot />
        <PieDePagina />
        <AvisoPrivacidadMovilModal />
        <Compartir />
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col-12">
          <div class="cargando">
            <div>
              <Spinner />
              <p class="text-center my-5">
                Cargando contenido...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PieDePagina from '@/components/Movil/componentes/PieDePagina.vue';
import AvisoPrivacidadMovilModal from '@/components/Movil/componentes/AvisoPrivacidad.vue';
import Compartir from '@/components/Compartir.vue';
import Spinner from '@/components/Spinner.vue';
import MenuMovil from '../componentes/Menu/Barra.vue';
import AvanceEntidad from '../../AvanceEntidad.vue';
import ConsultaVotacion from '../componentes/ConsultaVotacion.vue';

export default {
  name: 'LayoutMovil',
  components: {
    MenuMovil,
    AvanceEntidad,
    ConsultaVotacion,
    PieDePagina,
    AvisoPrivacidadMovilModal,
    Compartir,
    Spinner,
  },
  props: {
    seccion: {
      type: Boolean,
      default: () => false,
    },
    extranjero: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
  },
};
</script>

<style lang="scss" scoped>
.contenido {
  padding-top: 6.5rem !important;
}

.cargando {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100vh;

  .sk-chase {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
