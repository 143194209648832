<template>
  <div class="container-fluid barra">
    <div class="row">
      <div class="col-12 seccion-menu">
        <div class="contenedor-logo">
          <img src="@/assets/LogoLight.png" alt="" class="barra-logo" v-if="!esOscuro">
          <img src="@/assets/LogoDark.png" alt="" class="barra-logo" v-else>
        </div>
        <h4>
          <small style="font-weight: 500;">
            Elecciones Estatales de <br />
            <span class="primario">Baja California Sur</span> 2021
          </small>
        </h4>
        <div class="contenedor-boton">
          <button @click="mostrarMenu">
            <font-awesome-icon icon="bars" class="fa-2x" />
            <p>
              Menú
            </p>
          </button>
        </div>
      </div>
    </div>

    <MenuFlotante />
  </div>
</template>

<script>
import MenuFlotante from './MenuFlotante'

export default {
  name: 'MenuMovil',
  components: {
    MenuFlotante
  },
  methods: {
    mostrarMenu () {
      this.$store.commit('setearEstado', {
        propiedad: 'mostrarMiCasilla', payload: false
      })
      this.$bvModal.show('MenuFlotanteMovil')
    }
  },
  computed: {
    esOscuro () {
      return this.$store.state.esOscuro
    }
  }
}
</script>

<style lang="scss" scoped>
.barra {
  position: fixed;
  top: 0;
  z-index: 999;
}
.seccion-menu {
  background: #fff;
  box-shadow: 0px 3px 10px 0px #dedede;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 65px 1fr 40px;
  align-items: center;

  .contenedor-boton {
    float: right;

    button {
      appearance: none;
      background: transparent;
      border: none;
      text-align: center;

      p {
        margin-bottom: 0;
        font-size: 10px;
        font-weight: bold;
        margin-top: -3px;
      }
    }
  }

  .contenedor-logo {
    margin-right: .5rem;
    border-right: 2px solid black;
  }

  .barra-logo {
    max-width: 45px;
  }
}
</style>
